import * as d3 from 'd3'
import {
  intFeatureVals,
  floatFeatureVals,
  intFeatureLine,
  floatFeatureLine,
  eigRodPatternChanges
} from 'tools/hdf'
import React, { useEffect } from 'react'

const zip = (a, b) => a.map((k, i) => [k, b[i]])
const add = (a, b) => a + b
const mean = array => array.reduce(add) / array.length
const h = 250
const w = 700
const m = { r: 70, l: 40, t: 10, b: 30 }

const ignoreKeys = [
  'pin_by_pin_enrichment',
  'pin_by_pin_gad_concentration'
]

const needKeys = [
  'core_average_exposure_mwdst',
  'eigenvalue',
  'total_core_power_mwth',
  'total_core_flow_mlbmhr'
]

const EigPreviewGraph = props => {
    const hdf = props.hdf
    const keys = hdf.keys
      .filter(x => !ignoreKeys.includes(x))
    /* data */
    const exposure = keys
      .map(k => parseInt(hdf.get(k).get('cycle_exposure_mwdst').value))
    const eig = keys
      .filter(x => hdf.get(x).keys.includes('eigenvalue'))
      .map(k => parseFloat(hdf.get(k).get('eigenvalue').value))
    const power = keys
      .filter(x => hdf.get(x).keys.includes('total_core_power_mwth'))
      .map(k => parseFloat(hdf.get(k).get('total_core_power_mwth').value))
    const flow = keys
      .filter(x => hdf.get(x).keys.includes('total_core_flow_mlbmhr'))
      .map(k => parseFloat(hdf.get(k).get('total_core_flow_mlbmhr').value))
    /* lines */
    const eigLine = zip(eig, exposure)
      .filter(d => d[0] > 0)
      .sort((a,b) => b[1] - a[1])
    const powerLine = zip(power, exposure)
      .filter(d => d[0] > 0)
      .sort((a,b) => b[1] - a[1])
    const flowLine = zip(flow, exposure)
      .filter(d => d[0] > 0)
      .sort((a,b) => b[1] - a[1])
    /* scales */
    const xScale = d3.scaleLinear()
      .domain([Math.min(...exposure), Math.max(...exposure)])
      .range([m.l, w - m.r - 20])
    const powerScale = d3.scaleLinear()
      .domain([Math.min(...power), Math.max(...power)])
      .range([h - m.b, m.t])
    const flowScale = d3.scaleLinear()
      .domain([Math.min(...flow), Math.max(...flow)])
      .range([h - m.b, m.t])
    const eigScale = d3.scaleLinear()
      .domain([Math.min(...eig), Math.max(...eig)])
      .range([h - m.b, m.t])
    /* axes */
    const xAxis = g => g
      .attr('transform', `translate(0,${h - m.b})`)
      .call(d3.axisBottom(xScale).ticks(6))
    const eigAxis = g => g
      .attr('transform', `translate(${m.l},0)`)
      .call(d3.axisLeft(eigScale).ticks(6))
    /* rod patterns */
    const thesePatternChanges = eigRodPatternChanges(props.hdf)
    /* render */
    useEffect(() => {
      const svg = d3.select('#svg')
      svg.append('g').call(xAxis)
      svg.append('g').call(eigAxis)
      /* eig */
      svg.append('path')
        .datum(eigLine)
        .attr('fill', 'none')
        .attr('stroke', 'red')
        .attr('stroke-width', '1')
        .attr('d', d3.line()
          .x(d => xScale(d[1]))
          .y(d => eigScale(d[0])))
      svg.insert('text')
        .style('font', '9px sans-serif')
        .attr('y', 35)
        .attr('x', w - m.r + 10)
        .text('eigenvalue')
      svg.insert('circle')
        .attr('cy', 31.5)
        .attr('cx', w - m.r + 65)
        .attr('r', 3)
        .attr('fill', 'red')
      svg.insert('circle')
        .attr('cy', h - m.b + 14)
        .attr('cx', m.l - 20)
        .attr('r', 3)
        .attr('fill', 'red')
      /* power */
      svg.append('path')
        .datum(powerLine)
        .attr('fill', 'none')
        .attr('stroke', 'green')
        .attr('stroke-width', '1')
        .attr('d', d3.line()
          .x(d => xScale(d[1]))
          .y(d => powerScale(d[0])))
      svg.insert('text')
        .style('font', '9px sans-serif')
        .attr('y', 45)
        .attr('x', w - m.r + 10)
        .text('core power')
      svg.insert('circle')
        .attr('cy', 41.5)
        .attr('cx', w - m.r + 65)
        .attr('r', 3)
        .attr('fill', 'green')
      /* flow */
      svg.append('path')
        .datum(flowLine)
        .attr('fill', 'none')
        .attr('stroke', 'goldenrod')
        .attr('stroke-width', '1')
        .attr('d', d3.line()
          .x(d => xScale(d[1]))
          .y(d => flowScale(d[0])))
      svg.insert('text')
        .style('font', '9px sans-serif')
        .attr('y', 55)
        .attr('x', w - m.r + 10)
        .text('core flow')
      svg.insert('circle')
        .attr('cy', 51.5)
        .attr('cx', w - m.r + 65)
        .attr('r', 3)
        .attr('fill', 'goldenrod')
      /* rod patterns */
      svg.append('g')
        .selectAll('rpdot')
        .data(thesePatternChanges)
        .enter()
        .append('svg')
        .attr('x', d => xScale(d[1]) - 3)
        .attr('y', d => h - m.b - 13)
        .append('polygon')
        .attr('points', '3.5,0 0,7 3.5,5.5 7,7')
        .style('fill', 'red')
    }, [])
    return <svg id='svg' height={h} width={w}></svg>
}

export default EigPreviewGraph